import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Success() {
  return (
    <Layout>
      <SEO title="Success" />
      <Container>
        <h2>Thank you for registering your interest</h2>
        <p>We appreciate you contacting us here at safetylogz.</p>
        <p>
          One of our colleagues will get back in touch with you soon! Have a
          great day!
        </p>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  height: 100%;
  @media only screen and (max-width: 414px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`
